@import "src/scss/module";

.benefits {

  h2 {
    writing-mode: vertical-lr;
    transform: rotate(180deg) translateX(100%);
    position: absolute;
    bottom: -8px;
    left: -9px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;

    &:before {
      content: '';
      display: block;
      background-color: $primary;
      height: 110px;
      width: 5px;
      margin-bottom: 20px;
    }
  }

  [class*=col-] {
    min-height: 500px;

    &:nth-of-type(1) {
      order: 1;
    }

    &:nth-of-type(2) {
      order: 2;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &:nth-of-type(3) {
      order: 3;

      @include media-breakpoint-up(md) {
        order: 4;
      }
    }

    &:nth-of-type(4) {
      order: 4;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @include media-breakpoint-up(md) {
        order: 3;
      }

    }
  }

}