@import "src/scss/module";

.banner {

  p {
    a {
      color: $primary;
    }
  }
}

.buttons {
  display: inline-block;

  a {
    border-radius: 100px;
    padding: .5em 1em;
    font-size: $font-size-sm;
  }
}