@import "src/scss/module";

.tabs {

  &__content {
    background-color: $quinary;
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}